@import '../../_vars.scss';

/* Tackle responsive design using a card layout on mobile */

.table-wrapper {

	.table-controls {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		&.table-controls--bottom {
			margin-top: 1rem;
		}
	}

	.table-search {
		margin-left: auto;

		input {
			min-width: 300px;
			border-color: transparent;
			background-color: lighten($black, 85%);
		}
	}

	.table-pagination {

		.btn {
			font-weight: bold;

			&:not(:last-child) {
				margin-right: .6rem;
			}
		}
	}

	.table {

		&.table--no-head {
			border-radius: .3rem;
			border: 1px solid lighten($black, 70%);
			& > main > .row {
				border-width: 1px 0 0 0;

				&:first-child {
					border: 0;
				}
			}
		}

		& > header,
		& > main {

			.col {
				flex: 1;
				font-size: 1rem;
				line-height: 1.5;
				padding: .65rem;

				&.col--controls {
					display: flex;
					flex: 0;
					align-items: center;

					.btn {

						&:not(:last-child) {
							margin-right: .75rem;
						}
					}
				}

				&.col--center {
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		& > header {
			display: flex;
			border-bottom: 3px solid lighten($black, 70%);
			background-color: lighten($black, 85%);
			text-transform: uppercase;
			border-radius: .3rem .3rem 0 0;

			.col {
				font-size: .75rem;
				font-weight: bold;
				padding: 1rem .65rem;
				color: lighten($black, 25%);
			}
		}

		& > main {
			position: relative;

			.row {
				display: flex;
				margin: 0;
				border-width: 0 1px 1px 1px;
				border-style: solid;
				border-color: lighten($black, 70%);

				&.row--sep {
					border-top-width: 2px;
				}

				&:last-child {
					border-radius: 0 0 .3rem .3rem;
				}

				.col {
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.table-loading {
				position: absolute;
				z-index: 1;
				color: $brand-blue;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($white, .5);
				display: flex;
				justify-content: center;
				align-items: center;

				&.hide {
					display: none;
				}
			}
		}
	}
}